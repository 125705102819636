'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportConfigurationService

 # @description

###
class ReportConfigurationService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $translate
    ReportConfigurationManager
  ) ->

    @getReportConfiguration = (configurationId) ->
      configurationPromise = ReportConfigurationManager
        .one(configurationId)
        .get()
      return configurationPromise

    @createReportConfiguration = (source, options) ->
      defaults =
        source: null
        limit: null
        offset: null
        parent: null
        pinned: false
        opened: false
        fields: []
        filters: []
        label: $filter('ucfirst')($translate.instant('app.report.new-base-report'))

      options = angular.merge {}, defaults, options

      if not options.fields.length
        fields = []
        for k, v of source.fields
          if !angular.isArray(v.field) then fields.push { field: v.field, label: null }
        options.fields = fields

      if not options.source
        options.source = source.code

      configurationCreationPromise = ReportConfigurationManager
        .post options

      return configurationCreationPromise

    @patchConfiguration = (configuration, changes) ->
      configurationPatchPromise = ReportConfigurationManager
        .one(configuration['_id'])
        .patch changes
      return configurationPatchPromise

    @getListWithParams = (params) ->
      configurationGetListPromise = ReportConfigurationManager
        .getList params
      return configurationGetListPromise

    @removeConfiguration = (id) ->
      configurationRemovePromise = ReportConfigurationManager
        .one id
        .remove()
      return configurationRemovePromise

    @getSchedulerList = (cfg, params = {}) ->
      return ReportConfigurationManager
        .one cfg['_id']
        .one 'schedulers'
        .getList params

    @removeScheduler = (cfg, id) ->
      return ReportConfigurationManager
        .one cfg['_id']
        .one 'schedulers'
        .one id
        .remove()

    @patchScheduler = (cfg, id, changes) ->
      return ReportConfigurationManager
        .one cfg['_id']
        .one 'schedulers'
        .one id
        .patch changes

    @createScheduler = (cfg, data) ->
      return ReportConfigurationManager
        .one cfg['_id']
        .one 'schedulers'
        .customPOST data

angular
.module('mundoReporting')
.service 'ReportConfigurationService', ReportConfigurationService
